import { ProgramType } from '@newfront-insurance/account-api';
import { useQuery as useRouteQuery } from '@newfront-insurance/next-router-provider';
import { createProvider, useProvider } from '@newfront-insurance/react-provision';
import invariant from 'tiny-invariant';

import { TRPCProvider } from './trpc';

export const AccountDataProvider = createProvider(() => {
  const accountUuid = useRouteQuery<string>('accountUuid');
  const { useQuery } = useProvider(TRPCProvider);

  const { data: account } = useQuery(['account.getAccount', { accountUuid }], {
    suspense: true,
  });
  // Data is always loaded with suspense
  invariant(account, 'Data not loaded');

  return {
    ...account,
    isPnC: account.programType === ProgramType.PROPERTY_AND_CASUALTY,
    isEb: account.programType === ProgramType.EMPLOYEE_BENEFITS,
  };
});
