import { CircleSpinner } from '@newfront-insurance/core-ui';
import { LoginBoundary } from '@newfront-insurance/next-auth';
import type { ReactNode } from 'react';
import React, { Suspense } from 'react';

import { AccountAccessProvider } from '@/client/providers/account-access';
import { AccountContextProvider } from '@/client/providers/account-context';
import { AccountDataProvider } from '@/client/providers/account-data';
import { AuthProvider } from '@/client/providers/auth';

interface Props {
  children: ReactNode;
}

export function AccountLayout({ children }: Props): JSX.Element {
  return (
    <LoginBoundary authProvider={AuthProvider}>
      <AccountAccessProvider>
        <AccountContextProvider>
          <Suspense fallback={<CircleSpinner label="Loading account..." />}>
            <AccountDataProvider>{children}</AccountDataProvider>
          </Suspense>
        </AccountContextProvider>
      </AccountAccessProvider>
    </LoginBoundary>
  );
}
